<template>
  <div
    :class="[
      sideNavToggle.categoryOpen ? 'opened-categories' : 'default-categories',
    ]"
    id="navigation-mobile"
  >
    <div class="ps-panel__header" style="background-color: #000">
      <div class="ps-panel__header" style="background-color: #000">
        <div class="row">
          <div class="col-4 left-align mt-4">
            <router-link
              :to="{ name: 'my_profile' }"
              v-if="auth.isAuthenticated"
            >
              myPahukama
            </router-link>
            <router-link :to="{ name: 'login' }" v-else>myPahukama</router-link>
          </div>
          <div class="col-4">
            <a class="ps-logo" href="/">
              <img
                src="/img/logo.png"
                alt="Pahukama"
                max-width="100%"
                height="auto"
              />
            </a>
          </div>
          <div class="col-4 right-align mt-4">
          <button class="btn close-side-menu text-white" @click="[sideNavToggle.toggleCategoryOpen()]">CLOSE</button>
        </div>
        </div>
      </div>
    </div>
    <div class="ps-panel__header mt-4">
      <div class="row d-flex justify-content-center" style="width: 100%;">
       
          <h3>All Services</h3>
        
        
      </div>
    </div>
    <div class="ps-panel__content">
      <div class="row my-3 px-4">
        <div style="flex:0 0 50%" class=" d-flex justify-content-center">
          <div
            class="text-center mx-2 mt-4 shadow p-2"
            style="width: 90%; border-radius: 11px"
            @click="[sideNavToggle.toggleCategoryOpen()]"
          >
            <router-link
              :to="{ path: '/smart-topup' }"
              style="text-decoration: none"
            >
              <img src="/img/button3-copy.png" alt="">
              <p class="text-center font-weight-bold">Airtime and Data</p>
            </router-link>
          </div>
        </div>
        <div style="flex:0 0 50%" class=" d-flex justify-content-center min-w-full">
          <div
            class="text-center mx-2 mt-4 shadow p-2"
            style="width: 90%; border-radius: 11px"
            @click="[sideNavToggle.toggleCategoryOpen()]"
          >
            <router-link
              :to="{ path: '/dialfrom263' }"
              style="text-decoration: none"
            >
              <img src="/img/button1-copy.png" alt="">
              <p class="text-center font-weight-bold">Diaspora Calls</p>
            </router-link>
          </div>
        </div>

        <div style="flex:0 0 50%" class=" d-flex justify-content-center min-w-full">
          <div
            class="text-center mx-2 mt-4 shadow p-2"
            style="width: 90%; border-radius: 11px"
            @click="[sideNavToggle.toggleCategoryOpen()]"
          >
            <router-link
              :to="{ path: '/simple_pay_direct' }"
              style="text-decoration: none; align-content: space-between; height: 100%;"
              class="d-flex flex-column justify-content-between" 
            >
              <img src="/img/button4-copy.png" alt="">
              <p class="text-center font-weight-bold">Pay Bills</p>
            </router-link>
          </div>
        </div>

        <div style="flex:0 0 50%" class=" d-flex justify-content-center min-w-full">
          <div
            class="text-center mx-2 mt-4 shadow p-2"
            style="width: 90%; border-radius: 11px"
            @click="[sideNavToggle.toggleCategoryOpen()]"
          >
            <router-link
              :to="{ name: 'runner_requests' }"
              style="text-decoration: none; align-content: space-between; height: 100%;"
              class="d-flex flex-column justify-content-between"  
            >
              <img class="" src="/img/button2-copy.png" alt="">
              <p class="text-center font-weight-bold">Request a runner</p>
            </router-link>
          </div>
        </div>

        <div style="flex:0 0 50%" class=" d-flex justify-content-center min-w-full">
          <div
            class="text-center mx-2 mt-4 shadow p-2"
            style="width: 90%; border-radius: 11px"
            @click="[sideNavToggle.toggleCategoryOpen()]"
          >
            <router-link
              :to="{ path: '/prescriptions' }"
              style="text-decoration: none; align-content: space-between; height: 100%;"
              class="d-flex flex-column justify-content-between"  
            >
              <img class="" src="/img/button11-copy.png" alt="">
              <p class="text-center font-weight-bold">Care & Medication</p>
            </router-link>
          </div>
        </div>

        <div style="flex:0 0 50%" class=" d-flex justify-content-center min-w-full">
          <div
            class="text-center mx-2 mt-4 shadow p-2"
            style="width: 90%; border-radius: 11px"
            @click="[sideNavToggle.toggleCategoryOpen()]"
          >
            <router-link
              :to="{ path: '/listings/category' }"
              style="text-decoration: none; align-content: space-between; height: 100%;"
              class="d-flex flex-column justify-content-between"  
            >
              <img class="" src="/img/button9-copy.png" alt="">
              <p class="text-center font-weight-bold">Pahukama Listings</p>
            </router-link>
          </div>
        </div>


      </div>
      
      <div class="d-flex justify-content-center">
        <img style="width: 60%;"src="../assets/images/pahukama_white_bg.jpg" alt="Pahukama Logo">
    </div>
    </div>
  </div>
</template>
<script>
import CartMini from "@/components/CartMini.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  mixins: [global],
  data() {
    return {
      searchMethod: "Shop category",
      searchQuery: "",
      categories: [],
      shops: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      shop: (state) => state.shop,
      categories: (state) => state.categories,
      shops: (state) => state.shops,
    }),
  },
  mounted: function () {
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.isLoading = true;
      axios.get("shops/list").then((response) => {
        this.shops = response.data;
        this.shops.forEach((shop) => {
          if (
            !this.categories.some(
              (category) => category.id === shop.category.id
            )
          ) {
            this.categories.push(shop.category);
          }
        });
        this.isLoading = false;
      });
    },
  },
  components: {
    CartMini,
  },
};
</script>
<script setup>
import { sideNavToggle } from "./Navs/mobileNavs";
</script>

<style>
.default-categories {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
.opened-categories {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(0%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
</style>
