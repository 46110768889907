var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[_setup.sideNavToggle.productsOpen ? 'opened-menu' : 'default-menu'],attrs:{"id":"menu-products"}},[_c('div',{staticClass:"ps-panel__header",staticStyle:{"background-color":"#000"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 left-align mt-4"},[(_vm.auth.isAuthenticated)?_c('router-link',{attrs:{"to":{ name: 'my_profile' }}},[_vm._v(" myPahukama ")]):_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v("myPahukama")])],1),_vm._m(0),_c('div',{staticClass:"col-4 right-align mt-4"},[_c('button',{staticClass:"btn close-side-menu text-white",on:{"click":function($event){[_setup.sideNavToggle.toggleProductsOpen()]}}},[_vm._v(" CLOSE ")])])])]),_vm._m(1),_c('div',{staticClass:"row my-3 px-4"},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"flex":"0 0 50%"}},[_c('div',{staticClass:"text-center w-75 ml-4 mt-4 shadow pt-1",staticStyle:{"width":"90%","background-color":"#ffe8df","border-radius":"11px"},on:{"click":function($event){[_setup.sideNavToggle.toggleProductsOpen()]}}},[_vm._m(2)])]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"flex":"0 0 50%"}},[_c('div',{staticClass:"text-center w-75 mr-4 mt-4 shadow",staticStyle:{"width":"90%","background-color":"#ffe8df","border-radius":"11px"},on:{"click":function($event){[_setup.sideNavToggle.toggleProductsOpen()]}}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'category_products',
            params: { slug: 'informal-marketplace-center' },
          }}},[_c('i',{staticClass:"icon-store",staticStyle:{"font-size":"40px"}}),_c('p',{staticClass:"text-center font-weight-bold"},[_vm._v("PaTownship Mass Market")])])],1)])]),_c('div',{staticClass:"row my-2 px-4"},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"flex":"0 0 50%"}},[_c('div',{staticClass:"text-center w-75 ml-4 mt-4 shadow",staticStyle:{"width":"90%","background-color":"#ffe8df","border-radius":"11px"},on:{"click":function($event){[_setup.sideNavToggle.toggleProductsOpen()]}}},[_vm._m(3)])]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"flex":"0 0 50%"}},[_c('div',{staticClass:"text-center w-75 mr-4 mt-4 shadow",staticStyle:{"width":"90%","background-color":"#ffe8df","border-radius":"11px"},on:{"click":function($event){[_setup.sideNavToggle.toggleProductsOpen()]}}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'category_products',
            params: { slug: 'pharmacies-health-care' },
          }}},[_c('i',{staticClass:"lnr lnr-heart-pulse",staticStyle:{"font-size":"40px"}}),_c('p',{staticClass:"text-center px-3 font-weight-bold"},[_vm._v("Meds & Prescriptions")])])],1)])]),_c('div',{staticClass:"row my-2 px-4"},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"flex":"0 0 50%"}},[_c('div',{staticClass:"text-center w-75 ml-4 mt-4 shadow",staticStyle:{"width":"90%","background-color":"#ffe8df","border-radius":"11px"},on:{"click":function($event){[_setup.sideNavToggle.toggleProductsOpen()]}}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            path: '/shop/pahukama-building-materials'
          }}},[_c('i',{staticClass:"lnr lnr-construction",staticStyle:{"font-size":"40px"}}),_c('p',{staticClass:"text-center px-3 font-weight-bold"},[_vm._v("Building Materials")])])],1)]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"flex":"0 0 50%"}},[_c('div',{staticClass:"text-center w-75 mr-4 mt-4 pt-1 shadow",staticStyle:{"width":"90%","background-color":"#ffe8df","border-radius":"11px"},on:{"click":function($event){[_setup.sideNavToggle.toggleProductsOpen()]}}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            path: '/shop/pahukama-takeaways'
          }}},[_c('i',{staticClass:"lnr lnr-dinner",staticStyle:{"font-size":"40px"}}),_c('p',{staticClass:"text-center px-3 font-weight-bold"},[_vm._v("Takeaways")])])],1)])]),_c('div',{staticClass:"row my-3 px-4"},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"flex":"0 0 50%"}},[_c('div',{staticClass:"text-center w-75 ml-4 mt-4 shadow",staticStyle:{"width":"90%","background-color":"#ffe8df","border-radius":"11px"},on:{"click":function($event){[_setup.sideNavToggle.toggleProductsOpen()]}}},[_vm._m(4)])]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"flex":"0 0 50%"}},[_c('div',{staticClass:"text-center w-75 mr-4 mt-4 shadow",staticStyle:{"width":"90%","background-color":"#ffe8df","border-radius":"11px"},on:{"click":function($event){[_setup.sideNavToggle.toggleProductsOpen()]}}},[_vm._m(5)])])]),_c('div',{staticClass:"row my-3 px-4"},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"flex":"0 0 50%"}},[_c('div',{staticClass:"text-center w-75 ml-4 mt-4 shadow",staticStyle:{"width":"90%","background-color":"#ffe8df","border-radius":"11px"},on:{"click":function($event){[_setup.sideNavToggle.toggleProductsOpen()]}}},[_vm._m(6)])]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"flex":"0 0 50%"}},[_c('div',{staticClass:"text-center w-75 mr-4 mt-4 shadow",staticStyle:{"width":"90%","background-color":"#ffe8df","border-radius":"11px"},on:{"click":function($event){[_setup.sideNavToggle.toggleProductsOpen()]}}},[_vm._m(7)])])]),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-4"},[_c('a',{staticClass:"ps-logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":"/img/logo.png","alt":"Pahukama","max-width":"100%","height":"auto"}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ps-panel__header mt-4"},[_c('div',{staticClass:"row d-flex justify-content-center ml-1",staticStyle:{"width":"100%"}},[_c('h3',{staticClass:"text-center m-0"},[_vm._v("All Products")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"d-flex w-100 justify-content-center h-100",staticStyle:{"align-content":"space-between","flex-wrap":"wrap"},attrs:{"href":"/shop/pahukama-supermarket"}},[_c('img',{attrs:{"width":"40px","src":"/img/groceries.png"}}),_c('p',{staticClass:"w-100 px-3 text-center font-weight-bold"},[_vm._v(" Grocery & Essentials ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"d-flex w-100 justify-content-center h-100",staticStyle:{"align-content":"space-between","flex-wrap":"wrap"},attrs:{"href":"/shop/pahukama-treats"}},[_c('i',{staticClass:"icon-gift",staticStyle:{"font-size":"40px"}}),_c('p',{staticClass:"w-100 px-3 text-center font-weight-bold"},[_vm._v("Events, Gifts & Treats")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"d-flex w-100 justify-content-center h-100",staticStyle:{"align-content":"space-between","flex-wrap":"wrap"},attrs:{"href":"/shop/pahukama-greens"}},[_c('img',{attrs:{"width":"40px","src":"/img/vegitables.png"}}),_c('p',{staticClass:"w-100 px-3 text-center font-weight-bold"},[_vm._v(" Fresh Organic Greens ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"d-flex w-100 justify-content-center h-100",staticStyle:{"align-content":"space-between","flex-wrap":"wrap"},attrs:{"href":"/shop/pahukama-bakery"}},[_c('img',{attrs:{"width":"40px","src":"/img/bakery.png"}}),_c('p',{staticClass:"w-100 px-3 text-center font-weight-bold"},[_vm._v("Bakery, Cake & Deli")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"d-flex w-100 justify-content-center h-100",staticStyle:{"align-content":"space-between","flex-wrap":"wrap"},attrs:{"href":"/shop/pahukama-hardware"}},[_c('img',{attrs:{"width":"40px","src":"/img/hardware.png"}}),_c('p',{staticClass:"w-100 px-3 text-center font-weight-bold"},[_vm._v(" Hardware & Electricals ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"d-flex w-100 justify-content-center h-100",staticStyle:{"align-content":"space-between","flex-wrap":"wrap"},attrs:{"href":"/shop/pahukama-liquor"}},[_c('img',{attrs:{"width":"40px","src":"/img/liquor.png"}}),_c('p',{staticClass:"w-100 px-3 text-center font-weight-bold"},[_vm._v("Alcohol, Wine & Spirits")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticStyle:{"width":"35%"},attrs:{"src":require("../assets/images/pahukama_white_bg.jpg"),"alt":"Pahukama Logo"}})])
}]

export { render, staticRenderFns }