<template>
  <div
    :class="[sideNavToggle.productsOpen ? 'opened-menu' : 'default-menu']"
    id="menu-products"
  >
    <div class="ps-panel__header" style="background-color: #000">
      <div class="row">
        <div class="col-4 left-align mt-4">
          <router-link :to="{ name: 'my_profile' }" v-if="auth.isAuthenticated">
            myPahukama
          </router-link>
          <router-link :to="{ name: 'login' }" v-else>myPahukama</router-link>
        </div>
        <div class="col-4">
          <a class="ps-logo" href="/">
            <img
              src="/img/logo.png"
              alt="Pahukama"
              max-width="100%"
              height="auto"
            />
          </a>
        </div>
        <div class="col-4 right-align mt-4">
          <button
            class="btn close-side-menu text-white"
            @click="[sideNavToggle.toggleProductsOpen()]"
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
    <div class="ps-panel__header mt-4">
      <div class="row d-flex justify-content-center ml-1" style="width: 100%">
        <h3 class="text-center m-0">All Products</h3>
      </div>
    </div>
    <!--<div class="row my-3 px-4">
        <div class="col-6 d-flex justify-content-center">
            <div class="text-center mx-2 mt-4 shadow p-2" style="width: 90%; border-radius: 11px" @click="[sideNavToggle.toggleProductsOpen()]">
                <router-link :to="{ path: '/smart-topup' }" style="text-decoration: none;">
                    <i class="icon-phone" style="font-size: 50px; "></i>
                    <p class="text-center font-weight-bold">Airtime and Data</p>
                </router-link>
            </div>
        </div>
        <div class="col-6 d-flex justify-content-center min-w-full">
            <div class="text-center mx-2 mt-4  shadow p-2" style="width: 90%; border-radius: 11px" @click="[sideNavToggle.toggleProductsOpen()]">
                <router-link :to="{ path: '/dialfrom263' }" style="text-decoration: none;">
                    <i class="lnr lnr-phone-handset" style="font-size: 50px;"></i>
                    <p class="text-center font-weight-bold">Diaspora Calls</p>
                </router-link>
            </div>
        </div>
    </div>-->
    <div class="row my-3 px-4">
      <div style="flex: 0 0 50%" class="d-flex justify-content-center">
        <div
          class="text-center w-75 ml-4 mt-4 shadow pt-1 "
          style="width: 90%; background-color: #ffe8df; border-radius: 11px"
          @click="[sideNavToggle.toggleProductsOpen()]"
        >
          <a
            href="/shop/pahukama-supermarket"
            class="d-flex w-100 justify-content-center h-100"
            style="align-content: space-between; flex-wrap: wrap"
          >
            <img width="40px" src="/img/groceries.png" />
            <p class="w-100 px-3 text-center font-weight-bold">
              Grocery & Essentials
            </p>
          </a>
        </div>
      </div>
      <div style="flex: 0 0 50%" class="d-flex justify-content-center">
        <div
          class="text-center w-75 mr-4 mt-4 shadow"
          style="width: 90%; background-color: #ffe8df; border-radius: 11px"
          @click="[sideNavToggle.toggleProductsOpen()]"
        >
          <router-link
            :to="{
              name: 'category_products',
              params: { slug: 'informal-marketplace-center' },
            }"
            style="text-decoration: none"
          >
            <i class="icon-store" style="font-size: 40px"></i>
            <p class="text-center font-weight-bold">PaTownship Mass Market</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row my-2 px-4">
      <div style="flex: 0 0 50%" class="d-flex justify-content-center">
        <div
          class="text-center w-75 ml-4 mt-4 shadow"
          style="width: 90%; background-color: #ffe8df; border-radius: 11px"
          @click="[sideNavToggle.toggleProductsOpen()]"
        >
          <a
            href="/shop/pahukama-treats"
            class="d-flex w-100 justify-content-center h-100"
            style="align-content: space-between; flex-wrap: wrap"
          >
            <i class="icon-gift" style="font-size: 40px"></i>
            <p class="w-100 px-3 text-center font-weight-bold">Events, Gifts & Treats</p>
      </a>
        </div>
      </div>
      <div style="flex: 0 0 50%" class="d-flex justify-content-center">
        <div
          class="text-center w-75 mr-4 mt-4 shadow"
          style="width: 90%; background-color: #ffe8df; border-radius: 11px"
          @click="[sideNavToggle.toggleProductsOpen()]"
        >
          <router-link
            :to="{
              name: 'category_products',
              params: { slug: 'pharmacies-health-care' },
            }"
            style="text-decoration: none"
          >
            <i class="lnr lnr-heart-pulse" style="font-size: 40px"></i>
            <p class="text-center px-3 font-weight-bold">Meds & Prescriptions</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row my-2 px-4">
      <div style="flex: 0 0 50%" class="d-flex justify-content-center">
        <div
          class="text-center w-75 ml-4 mt-4 shadow"
          style="width: 90%; background-color: #ffe8df; border-radius: 11px"
          @click="[sideNavToggle.toggleProductsOpen()]"
        >
          <router-link
            :to="{
              path: '/shop/pahukama-building-materials'
            }"
            style="text-decoration: none"
          >
            <i class="lnr lnr-construction" style="font-size: 40px"></i>
            <p class="text-center px-3 font-weight-bold">Building Materials</p>
          </router-link>
        </div>
      </div>
      <div style="flex: 0 0 50%" class="d-flex justify-content-center">
        <div
          class="text-center w-75 mr-4 mt-4 pt-1 shadow"
          style="width: 90%; background-color: #ffe8df; border-radius: 11px"
          @click="[sideNavToggle.toggleProductsOpen()]"
        >
          <router-link
            :to="{
              path: '/shop/pahukama-takeaways'
            }"
            style="text-decoration: none"
            class=""
          >
            <i class="lnr lnr-dinner" style="font-size: 40px"></i>
            <p class="text-center px-3 font-weight-bold">Takeaways</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row my-3 px-4">
      <div style="flex: 0 0 50%" class="d-flex justify-content-center">
        <div
          class="text-center w-75 ml-4 mt-4 shadow"
          style="width: 90%; background-color: #ffe8df; border-radius: 11px"
          @click="[sideNavToggle.toggleProductsOpen()]"
        >
          <a
            href="/shop/pahukama-greens"
            class="d-flex w-100 justify-content-center h-100"
            style="align-content: space-between; flex-wrap: wrap"
          >
            <img width="40px" src="/img/vegitables.png" />
            <p class="w-100 px-3 text-center font-weight-bold">
              Fresh Organic Greens
            </p>
          </a>
        </div>
      </div>
      <div style="flex: 0 0 50%" class="d-flex justify-content-center">
        <div
          class="text-center w-75 mr-4 mt-4 shadow"
          style="width: 90%; background-color: #ffe8df; border-radius: 11px"
          @click="[sideNavToggle.toggleProductsOpen()]"
        >
          <a
            href="/shop/pahukama-bakery"
            class="d-flex w-100 justify-content-center h-100"
            style="align-content: space-between; flex-wrap: wrap"
          >
            <img width="40px" src="/img/bakery.png" />
            <p class="w-100 px-3 text-center font-weight-bold">Bakery, Cake & Deli</p>
            </a>
        </div>
      </div>
    </div>

    <div class="row my-3 px-4">
      <div style="flex: 0 0 50%" class="d-flex justify-content-center">
        <div
          class="text-center w-75 ml-4 mt-4 shadow"
          style="width: 90%; background-color: #ffe8df; border-radius: 11px"
          @click="[sideNavToggle.toggleProductsOpen()]"
        >
          <a
            href="/shop/pahukama-hardware"
            class="d-flex w-100 justify-content-center h-100"
            style="align-content: space-between; flex-wrap: wrap"
          >
            <img width="40px" src="/img/hardware.png" />
            <p class="w-100 px-3 text-center font-weight-bold">
              Hardware & Electricals
            </p>
          </a>
        </div>
      </div>
      <div style="flex: 0 0 50%" class="d-flex justify-content-center">
        <div
          class="text-center w-75 mr-4 mt-4 shadow"
          style="width: 90%; background-color: #ffe8df; border-radius: 11px"
          @click="[sideNavToggle.toggleProductsOpen()]"
        >
          <a
            href="/shop/pahukama-liquor"
            class="d-flex w-100 justify-content-center h-100"
            style="align-content: space-between; flex-wrap: wrap"
          >
            <img width="40px" src="/img/liquor.png" />
            <p class="w-100 px-3 text-center font-weight-bold">Alcohol, Wine & Spirits</p>
            </a>
        </div>
      </div>
    </div>


    <div class="d-flex justify-content-center">
      <img
        style="width: 35%"
        src="../assets/images/pahukama_white_bg.jpg"
        alt="Pahukama Logo"
      />
    </div>
  </div>
</template>
<script setup>
import { sideNavToggle } from "./Navs/mobileNavs";
//import { menuOpen } from "./Navs/mobileNavs";
//import { toggle } from "./Navs/mobileNavs";
</script>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      searchMethod: "Product",
      searchQuery: "",
      currentPlace: {},
    };
  },

  components: {},
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      shop: (state) => state.shop,
    }),
  },
  methods: {
    search() {
      if (this.searchMethod === "Shop location") {
        location.replace(`/search-shops-result/${this.searchQuery}/location`);
      } else if (this.searchMethod === "Shop category") {
        location.replace(`/search-shops-result/${this.searchQuery}/category`);
      } else if (this.searchMethod === "Shop") {
        location.replace(`/search-shops-result/${this.searchQuery}/shop`);
      } else {
        this.$router.push({
          name: "products_result",
          params: { query: this.searchQuery },
        });

        location.replace(`/search-products-result/${this.searchQuery}`);
      }
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "home" });
    },
    handleClick() {
      this.$emit("change-style");
    },
  },
};
</script>

<style>
.default-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
.opened-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(0%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
</style>
